<template>
  <div>
    <div>
      <div class="row">
        <div class="col-md-9">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <DualListBoxDevices
                :source="devices"
                :destination="selectedDevices"
                label="name"
                @onChangeList="onChangeList"
              />
              <div class="pt-4 px-4">
                <button
                  class="btn btn-primary btn-block font-weight-bold"
                  @click="generate"
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <DatePicker
                ref="picker"
                v-model="range"
                mode="date"
                :attributes="attributes"
                :masks="masks"
                locale="hr"
                is-range
                is24hr
                is-expanded
                :columns="$screens({ default: 1, lg: 1 })"
                :max-date="this.getLastDayOfPreviousMonth()"
              >
              </DatePicker>

              <button
                @click="lastMonth"
                class="btn btn-light-primary btn-block font-weight-bold mt-2"
              >
                Last month
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-custom gutter-b" v-if="this.isGenerated">
        <div class="card-body">
          <EbitdaTable :traffic="generatedResults" />
        </div>
      </div>
      <div
        v-if="this.showSpinner"
        class="col-12 d-flex align-items-center justify-content-center pb-5"
        style="height: 540px"
      >
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DualListBoxDevices from '@/view/content/components/dual-list-box/DualListBoxDevices'
import add from 'date-fns/add'
import ApiService from '@/core/services/api.service'
import startOfMonth from 'date-fns/startOfMonth'
import getMonth from 'date-fns/getMonth'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import endOfMonth from 'date-fns/endOfMonth'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

import EbitdaTable from './_components/Table.vue'

export default {
  name: 'Ebitda',
  components: {
    DatePicker,
    DualListBoxDevices,
    EbitdaTable,
  },
  data() {
    return {
      devices: [],
      selectedDevices: [],
      generatedResults: [],
      resultsDays: [],
      totalItems: null,
      range: {
        start: add(new Date(), { days: -1 }).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      showAlert: false,
      alertMsg: null,

      base: 0,
      tax: 0,
      total: 0,

      isGenerated: false,
      showSpinner: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'EBITDA', route: '/ebitda/list' },
    ])

    if (this.currentUserRole === 'ROLE_SUPER_ADMIN') {
      ApiService.get(
        'select-device',
        `?companyId=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}`
      ).then(({ data }) => {
        this.totalItems = data.length
        this.devices = data
        this.devices = this.devices.filter(
          (device) =>
            (device.isSaldoReducer !== true) & (device.isDemoDevice !== true)
        )
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name + ' | ' + device.location + ' | ' + device.tag
        })
      })
    }
  },
  methods: {
    generate() {
      this.isGenerated = false
      if (
        this.selectedDevices.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select device/s & pick range'
        this.showAlert = true

        return
      }

      this.showSpinner = true
      let num = 0

      const after = format(this.range.start, "yyyy-MM-dd'T'00:00:00")
      const before = format(this.range.end, "yyyy-MM-dd'T'23:59:59")

      this.selectedDevices.forEach((device) => {
        ApiService.get(
          '/device',
          `/${device.id}/ebitda?after=${after}&before=${before}`
        ).then(({ data }) => {
          this.generatedResults.push({
            id: data.id,
            imei: data.imei,
            deviceName: data.deviceName,
            location: data.location || '',
            lessor: data.lessor || '',
            netto: data.netto,
            nettoLease: data.nettoLease,
            deviceLocationEbitda: data.deviceLocationEbitda,
            tag: data.tag,
            prizeCost: data.prizeCost,
            companyNetto: data.companyNetto,
            ebitda: data.ebitda,
          })

          num++
          if (num === this.selectedDevices.length) {
            let totalNetto = 0
            let totalNettoLease = 0
            let totalDeviceLocationEbitda = 0
            let totalPrizeCost = 0
            let totalCompanyNetto = 0
            let totalEbitda = 0

            this.generatedResults.forEach((data) => {
              totalNetto += data.netto
              totalNettoLease += data.nettoLease
              totalDeviceLocationEbitda += data.deviceLocationEbitda
              totalPrizeCost += data.prizeCost
              totalCompanyNetto += data.companyNetto
              totalEbitda += data.ebitda
            })

            this.generatedResults.push({
              id: null,
              imei: ' ',
              deviceName: 'TOTAL',
              location: '',
              lessor: '',
              netto: totalNetto,
              nettoLease: totalNettoLease,
              deviceLocationEbitda: totalDeviceLocationEbitda,
              tag: '',
              prizeCost: totalPrizeCost,
              companyNetto: totalCompanyNetto,
              ebitda: totalEbitda,
            })

            setTimeout(() => {
              this.isGenerated = true
              this.showSpinner = false
            }, 1000)
          }
        })
      })
    },

    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },

    resetAll() {
      this.generatedResults = []
      this.resultsDays = []
      this.totalItems = null
      this.range.start = null
      this.range.end = null
      this.base = 0
      this.tax = 0
      this.total = 0
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false

      this.$refs.picker.updateValue({ start: null, end: null })
    },
    onChangeList: function ({ source, destination }) {
      this.devices = source
      this.selectedDevices = destination
    },
    setStartEndDate() {
      this.isGenerated = false
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
    getLastDayOfPreviousMonth() {
      const now = new Date()
      const lastMonth = new Date(now.getFullYear(), now.getMonth(), 0) // 0 gets the last day of the previous month
      return lastMonth
    },
  },
}
</script>
